import { email } from 'vuelidate/lib/validators'
export default {
  methods: {
    alterIframeUrlByParams(iframeUrl, context) {
      if (this.$store.state.ui.currentLocale) {
        const localeExp = /locale=([a-z]{2}_[A-Z]{2}|[a-z]{2})/

        if (iframeUrl.match(localeExp)) {
          iframeUrl = iframeUrl.replace(
            localeExp,
            'locale=' + this.$store.state.ui.currentLocale
          )
        } else {
          iframeUrl +=
            '&locale=' + encodeURIComponent(this.$store.state.ui.currentLocale)
        }
      }

      if (context === 'login') {
        let emailParam = this.$route.query.email

        if (emailParam && email(emailParam)) {
          iframeUrl += '&login_hint=' + encodeURIComponent(emailParam)
        }
      }

      if (context === 'registration') {
        let emailParam = this.$route.query.email

        if (emailParam && email(emailParam)) {
          iframeUrl += '&email=' + encodeURIComponent(emailParam)
        }

        let firstnameParam = this.$route.query.firstname

        if (firstnameParam) {
          iframeUrl += '&firstName=' + encodeURIComponent(firstnameParam)
        }

        let lastnameParam = this.$route.query.lastname

        if (lastnameParam) {
          iframeUrl += '&familyName=' + encodeURIComponent(lastnameParam)
        }
      }

      iframeUrl = iframeUrl.replace(
        /<client_domain>/g,
        document.location.hostname
      )

      if (window.location.href.match(/next=(.*?)($|&)/)) {
        const next = window.location.href.match(/next=(.*?)($|&)/)
        if (decodeURIComponent(next[1]).includes('idp-portal')) {
          iframeUrl += encodeURIComponent('&nextUrlIdpPortal=True')
        }
      }

      return iframeUrl
    }
  }
}
